import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LoginModel} from '../models/requests/security/login.model';
import {RegisterModel} from '../models/requests/security/register.model';
import {Injectable} from '@angular/core';
import {AccountRecoveryModel} from '../models/requests/account/account-recovery.model';
import {AwardModel} from '../models/requests/award/award.model';
import {AccountUploadModel} from '../models/requests/account/account-upload.model';
import {ScholarshipModel} from '../models/requests/scholarship/scholarship.model';
import {AwardSettingsModel} from '../models/requests/award/award-settings.model';
import {ScholarshipSettingsModel} from '../models/requests/scholarship/scholarship-settings.model';
import {AwardStatusModel} from '../models/requests/award/award-status.model';
import {ScholarshipStatusModel} from '../models/requests/scholarship/scholarship-status.model';
import {ProposalSettingsModel} from '../models/requests/proposal/proposal-settings.model';
import {PoolModel} from '../models/requests/pool/pool.model';
import {PoolStatusModel} from '../models/requests/pool/pool-status.model';
import {PoolSettingsModel} from '../models/requests/pool/pool-settings.model';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {AwardSettlementModel} from '../models/requests/award/award-settlement.model';
import {ScholarshipSettlementModel} from '../models/requests/scholarship/scholarship-settlement.model';
import {RecoveryPasswordModel} from '../models/requests/account/recovery-password.model';
import Swal from 'sweetalert2';
import {AccountDeactivationModel} from '../models/requests/account/account-deactivation.model';
import {ChangePasswordModel} from '../models/requests/account/change-password.model';
import {ProposalSettlementModel} from '../models/requests/proposal/proposal-settlement.model';
import {ProposalStatusModel} from '../models/requests/proposal/proposal-status.model';
import {ProposalModel} from '../models/requests/proposal/proposal.model';
import {AuthorModel} from '../models/requests/author/author.model';
import {MembershipModel} from '../models/requests/membership/membership.model';
import {PublisherModel} from '../models/requests/publisher/publisher.model';
import {PublicationModel} from '../models/requests/publication/publication.model';
import {PublicationCostModel} from '../models/requests/publicationCost/publicationCost.model';
import {PoolPublicationTypeModel} from '../models/requests/pool/pool-publication-type.model';

@Injectable()
export class ApiService {

  private backendUrl = environment.backendUrl;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {
  }

  private static getHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: localStorage.getItem('token')
      })
    };
  }

  private static getParams(params: any): any {
    const clonedParams = { ...params };
    console.log('clonedParams', clonedParams);
    Object.keys(clonedParams).forEach(field => {
      if (clonedParams[field] == null || clonedParams[field] === 'null') {
        delete clonedParams[field];
      }
    });
    return {params: clonedParams};
  }

  private static getOptions(params: any) {
    return { ...ApiService.getHeader(), ...ApiService.getParams(params) };
  }

  private static getAnonymousHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
  }

  private static postFileHeader() {
    return {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: localStorage.getItem('token')
      })
    };
  }

  public checkToken() {
    if (this.authService.isTokenSet()) {
      this.getUserByToken(this.authService.getToken()).subscribe((data: any) => {
      }, error => {
        if (error.status === 400) {
          Swal.fire(
            'Sesja wygasła!',
            'Zaloguj się ponownie do serwisu.',
            'error'
          );
          this.router.navigate(['/']);
        }
      });
    }
  }

  // Security
  public login(loginModel: LoginModel) {
    return this.httpClient.post(this.backendUrl + 'security/login/', loginModel, ApiService.getAnonymousHeader());
  }

  public register(registerModel: RegisterModel) {
    return this.httpClient.post(this.backendUrl + 'security/register/', registerModel, ApiService.getAnonymousHeader());
  }

  public accountRecovery(accountRecovery: AccountRecoveryModel) {
    return this.httpClient.post(this.backendUrl + 'security/recovery-password/', accountRecovery, ApiService.getAnonymousHeader());
  }

  public accountDeactivation(accountDeactivation: AccountDeactivationModel) {
    return this.httpClient.post(this.backendUrl + 'security/account-deactivation/', accountDeactivation, ApiService.getHeader());
  }

  public accountActivation(activationKey: string) {
    return this.httpClient.post(this.backendUrl + 'security/account-activation/', {activationKey}, ApiService.getAnonymousHeader());
  }

  public changePasswordByRecovery(recoveryPassword: RecoveryPasswordModel) {
    return this.httpClient.post(this.backendUrl + 'security/change-password-by-recovery/',
      recoveryPassword,
      ApiService.getAnonymousHeader());
  }

  public changePassword(changePassword: ChangePasswordModel) {
    return this.httpClient.post(this.backendUrl + 'security/change-password/',
      changePassword,
      ApiService.getHeader());
  }

  // User
  public getUser() {
    return this.httpClient.get(this.backendUrl + 'user/', ApiService.getHeader());
  }

  public getUserById(id: number) {
    return this.httpClient.get(this.backendUrl + 'user/' + id + '/', ApiService.getHeader());
  }

  public getUserByToken(token: string) {
    return this.httpClient.get(this.backendUrl + 'user/token/' + token + '/', ApiService.getAnonymousHeader());
  }

  public getAllUsers() {
    return this.httpClient.get(this.backendUrl + 'user/all/', ApiService.getHeader());
  }

  public updateUser(id: number, accountUpload: AccountUploadModel) {
    return this.httpClient.put(this.backendUrl + 'user/' + id + '/', accountUpload, ApiService.getHeader());
  }

  public createUser(data: AccountUploadModel) {
    return this.httpClient.post(this.backendUrl + 'user/create/', data, ApiService.getHeader());
  }

  public deleteUser(id: number) {
    return this.httpClient.delete(this.backendUrl + 'user/' + id + '/', ApiService.getHeader());
  }

  // Award
  public getAwards(options) {
    if (options.awardStatus) {
      return this.httpClient.get(this.backendUrl + 'admin/award/?status=' + options.awardStatus, ApiService.getHeader());
    }

    return this.httpClient.get(this.backendUrl + 'admin/award/', ApiService.getHeader());
  }

  public getAward(id: number) {
    return this.httpClient.get(this.backendUrl + 'admin/award/' + id + '/', ApiService.getHeader());
  }

  public getAwardStatistics() {
    return this.httpClient.get(this.backendUrl + 'admin/award/statistics/', ApiService.getHeader());
  }

  public postAward(award: AwardModel) {
    return this.httpClient.post(this.backendUrl + 'admin/award/', award, ApiService.getHeader());
  }

  public updateAward(id: number, award: AwardModel) {
    return this.httpClient.put(this.backendUrl + 'admin/award/' + id + '/', award, ApiService.getHeader());
  }

  public deleteAward(id: number) {
    return this.httpClient.delete(this.backendUrl + 'admin/award/' + id + '/', ApiService.getHeader());
  }

  // Award status
  public getAwardStatus(id: number) {
    return this.httpClient.get(this.backendUrl + 'admin/award-status/' + id + '/', ApiService.getHeader());
  }

  public acceptAward(award: AwardStatusModel) {
    return this.httpClient.post(this.backendUrl + 'admin/award-status/accept/', award, ApiService.getHeader());
  }

  public declineAward(award: AwardStatusModel) {
    return this.httpClient.post(this.backendUrl + 'admin/award-status/decline/', award, ApiService.getHeader());
  }

  // Award settlement

  public postAwardSettlement(awardSettlement: AwardSettlementModel) {
    return this.httpClient.post(this.backendUrl + 'admin/award-settlement/', awardSettlement, ApiService.getHeader());
  }

  public updateAwardSettlement(id: number, awardSettlement: AwardSettlementModel) {
    return this.httpClient.put(this.backendUrl + 'admin/award-settlement/' + id + '/', awardSettlement, ApiService.getHeader());
  }

  // Award settings

  public getAwardSettings() {
    return this.httpClient.get(this.backendUrl + 'admin/award-settings/', ApiService.getHeader());
  }

  public updateAwardSettings(settings: AwardSettingsModel) {
    return this.httpClient.put(this.backendUrl + 'admin/award-settings/', settings, ApiService.getHeader());
  }

  // Scholarship
  public getScholarships(options) {
    if (options.scholarshipStatus) {
      return this.httpClient.get(this.backendUrl + 'admin/scholarship/?status=' + options.scholarshipStatus, ApiService.getHeader());
    }

    return this.httpClient.get(this.backendUrl + 'admin/scholarship/', ApiService.getHeader());
  }

  public getScholarship(id: number) {
    return this.httpClient.get(this.backendUrl + 'admin/scholarship/' + id + '/', ApiService.getHeader());
  }

  public getScholarshipStatistics() {
    return this.httpClient.get(this.backendUrl + 'admin/scholarship/statistics/', ApiService.getHeader());
  }

  public postScholarship(scholarship: ScholarshipModel) {
    return this.httpClient.post(this.backendUrl + 'admin/scholarship/', scholarship, ApiService.getHeader());
  }

  public updateScholarship(id: number, scholarship: ScholarshipModel) {
    return this.httpClient.put(this.backendUrl + 'admin/scholarship/' + id + '/', scholarship, ApiService.getHeader());
  }

  public deleteScholarship(id: number) {
    return this.httpClient.delete(this.backendUrl + 'admin/scholarship/' + id + '/', ApiService.getHeader());
  }

  // Scholarship status
  public getScholarshipStatus(id: number) {
    return this.httpClient.get(this.backendUrl + 'admin/scholarship-status/' + id + '/', ApiService.getHeader());
  }

  public acceptScholarship(scholarship: ScholarshipStatusModel) {
    return this.httpClient.post(this.backendUrl + 'admin/scholarship-status/accept/', scholarship, ApiService.getHeader());
  }

  public declineScholarship(scholarship: ScholarshipStatusModel) {
    return this.httpClient.post(this.backendUrl + 'admin/scholarship-status/decline/', scholarship, ApiService.getHeader());
  }

  // Scholarship settlement

  public postScholarshipSettlement(scholarshipSettlement: ScholarshipSettlementModel) {
    return this.httpClient.post(this.backendUrl + 'admin/scholarship-settlement/', scholarshipSettlement, ApiService.getHeader());
  }

  public updateScholarshipSettlement(id: number, scholarshipSettlement: ScholarshipSettlementModel) {
    return this.httpClient.put(
      this.backendUrl + 'admin/scholarship-settlement/' + id + '/',
      scholarshipSettlement,
      ApiService.getHeader()
    );
  }

  // Scholarship settings

  public getScholarshipSettings() {
    return this.httpClient.get(this.backendUrl + 'admin/scholarship-settings/', ApiService.getHeader());
  }

  public updateScholarshipSettings(settings: ScholarshipSettingsModel) {
    return this.httpClient.put(this.backendUrl + 'admin/scholarship-settings/', settings, ApiService.getHeader());
  }


  // Pools
  public getPools(options) {
    if (options.poolStatus) {
      return this.httpClient.get(this.backendUrl + 'admin/pool/?status=' + options.poolStatus, ApiService.getHeader());
    }

    return this.httpClient.get(this.backendUrl + 'admin/pool/', ApiService.getHeader());
  }

  public getAcceptedPools() {
    return this.httpClient.get(this.backendUrl + 'pool/', ApiService.getHeader());
  }


  public getPool(id: number) {
    return this.httpClient.get(this.backendUrl + 'admin/pool/' + id + '/', ApiService.getHeader());
  }

  public postPool(pool: PoolModel) {
    return this.httpClient.post(this.backendUrl + 'admin/pool/', pool, ApiService.getHeader());
  }

  public updatePool(id: number, pool: PoolModel) {
    return this.httpClient.put(this.backendUrl + 'admin/pool/' + id + '/', pool, ApiService.getHeader());
  }

  public deletePool(id: number) {
    return this.httpClient.delete(this.backendUrl + 'admin/pool/' + id + '/', ApiService.getHeader());
  }

  // Pool publication type
  public getPoolPublicationTypesForPool(pool: number) {
    return this.httpClient.get(this.backendUrl + 'pool-publication-type/pool/' + pool + '/', ApiService.getHeader());
  }

  public postPoolPublicationType(publicationType: PoolPublicationTypeModel) {
    return this.httpClient.post(this.backendUrl + 'admin/pool-publication-type/', publicationType, ApiService.getHeader());
  }

  // Pool status
  public getPoolStatus(id: number) {
    return this.httpClient.get(this.backendUrl + 'admin/pool-status/' + id + '/', ApiService.getHeader());
  }

  public acceptPool(pool: PoolStatusModel) {
    return this.httpClient.post(this.backendUrl + 'admin/pool-status/accept/', pool, ApiService.getHeader());
  }

  public declinePool(pool: PoolStatusModel) {
    return this.httpClient.post(this.backendUrl + 'admin/pool-status/decline/', pool, ApiService.getHeader());
  }

  // Pool settings

  public getPoolSettings() {
    return this.httpClient.get(this.backendUrl + 'admin/pool-settings/', ApiService.getHeader());
  }

  public updatePoolSettings(settings: PoolSettingsModel) {
    return this.httpClient.put(this.backendUrl + 'admin/pool-settings/', settings, ApiService.getHeader());
  }

  // Proposal for publisher
  public getPreviewProposal(id: number) {
    const headers = ApiService.getHeader();
    const options = {
      responseType: 'blob',
      headers: headers.headers,
    };
    // @ts-ignore
    return this.httpClient.get(this.backendUrl + 'proposal/preview/' + id, options);
  }

  public getLoggedPublisherProposals() {
    return this.httpClient.get(this.backendUrl + 'proposal/', ApiService.getHeader());
  }

  public getProposalByPublisher(id: number) {
    return this.httpClient.get(this.backendUrl + 'proposal/' + id + '/', ApiService.getHeader());
  }

  public postProposalEmailsRequest(id, data) {
    return this.httpClient.post(this.backendUrl + 'proposal/edit-emails-request/' + id + '/', data, ApiService.getHeader());
  }

  public getProposalEmailsRequestConfirm(hash) {
    return this.httpClient.get(this.backendUrl + 'proposal/edit-emails-request-confirm/' + hash + '/', ApiService.getHeader());
  }


  // Proposal
  public getProposal(id: number) {
    return this.httpClient.get(this.backendUrl + 'admin/proposal/' + id + '/', ApiService.getHeader());
  }

  public getProposals(options) {
    return this.httpClient.get(this.backendUrl + 'admin/proposal/', ApiService.getOptions(options));
  }

  public getProposalStatistics() {
    return this.httpClient.get(this.backendUrl + 'admin/proposal/statistics/', ApiService.getHeader());
  }

  public updateProposal(id: number, proposal) {
    return this.httpClient.put(this.backendUrl + 'admin/proposal/' + id + '/', proposal, ApiService.getHeader());
  }

  public deleteProposal(id: number) {
    return this.httpClient.delete(this.backendUrl + 'admin/proposal/' + id + '/', ApiService.getHeader());
  }

  // Proposal status
  public getProposalStatus(id: number) {
    return this.httpClient.get(this.backendUrl + 'admin/proposal-status/' + id + '/', ApiService.getHeader());
  }

  public acceptProposal(proposal: ProposalStatusModel) {
    return this.httpClient.post(this.backendUrl + 'admin/proposal-status/accept/', proposal, ApiService.getHeader());
  }

  public declineProposal(proposal: ProposalStatusModel) {
    return this.httpClient.post(this.backendUrl + 'admin/proposal-status/decline/', proposal, ApiService.getHeader());
  }

  // Proposal settlement

  public postProposalSettlement(proposalSettlementModel: ProposalSettlementModel) {
    return this.httpClient.post(this.backendUrl + 'admin/proposal-settlement/', proposalSettlementModel, ApiService.getHeader());
  }

  public updateProposalSettlement(id: number, proposalSettlementModel: ProposalSettlementModel) {
    return this.httpClient.put(
      this.backendUrl + 'admin/proposal-settlement/' + id + '/',
      proposalSettlementModel,
      ApiService.getHeader()
    );
  }

  // Proposal settings

  public getProposalSettings() {
    return this.httpClient.get(this.backendUrl + 'admin/proposal-settings/', ApiService.getHeader());
  }

  public updateProposalSettings(settings: ProposalSettingsModel) {
    return this.httpClient.put(this.backendUrl + 'admin/proposal-settings/', settings, ApiService.getHeader());
  }

  // Logs
  public getLogs(type) {
    return this.httpClient.get(this.backendUrl + 'log/' + type + '/', ApiService.getHeader());
  }

  // File
  public postFile(fileToUpload: File) {
    const formData: FormData = new FormData();

    formData.append('file', fileToUpload);
    return this.httpClient.post(this.backendUrl + 'admin/file/', formData, ApiService.postFileHeader());
  }

  public deleteFile(id: number) {
    return this.httpClient.delete(this.backendUrl + 'admin/file/' + id + '/', ApiService.getHeader());
  }

  public downloadFile(id) {
    return this.httpClient.get(this.backendUrl + 'admin/file/download/' + id + '/', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: localStorage.getItem('token')
      }),
      responseType: 'arraybuffer',
    });
  }

  // drafts
  public getDraft() {
    return this.httpClient.get(this.backendUrl + 'publisher/draft/', ApiService.getHeader());
  }

  public saveDraft(data) {
    return this.httpClient.post(this.backendUrl + 'publisher/draft/', data, ApiService.getHeader());
  }

  // Proposal
  public postProposal(proposalModel: ProposalModel) {
    // tslint:disable-next-line:one-variable-per-declaration
    const
      uc = c => c >= 'a' && c <= 'z'
        ? String.fromCharCode(c.charCodeAt() - 32)
        : c,
      toUpperCase = (key, value) => typeof value === 'string'
        ? Array.from(value, uc).join('')
        : value;

    proposalModel =  JSON.parse(JSON.stringify(proposalModel), toUpperCase);
    return this.httpClient.post(this.backendUrl + 'proposal/', proposalModel, ApiService.getHeader());
  }


  public putProposal(proposalModel: ProposalModel) {
    return this.httpClient.put(this.backendUrl + 'proposal/' + proposalModel.id + '/', proposalModel, ApiService.getHeader());
  }

  // Author
  public postAuthor(authorModel: AuthorModel) {
    return this.httpClient.post(this.backendUrl + 'author/', authorModel, ApiService.getHeader());
  }

  public postAuthorEmailAvailability(email?: string) {
    return this.httpClient.post(this.backendUrl + 'author/email-availability/', {email}, ApiService.getHeader());
  }

  public putAuthor(authorModel: AuthorModel) {
    return this.httpClient.put(this.backendUrl + 'author/' + authorModel.id + '/', authorModel, ApiService.getHeader());
  }

  // Membership
  public postMembership(membershipModel: MembershipModel) {
    return this.httpClient.post(this.backendUrl + 'membership/', membershipModel, ApiService.getHeader());
  }

  public putMembership(membershipModel: MembershipModel) {
    return this.httpClient.put(this.backendUrl + 'membership/' + membershipModel.id + '/', membershipModel, ApiService.getHeader());
  }

  // Publisher
  public postPublisher(publisherModel: PublisherModel) {
    return this.httpClient.post(this.backendUrl + 'publisher/', publisherModel, ApiService.getHeader());
  }

  public postPublisherEmailAvailability(email?: string) {
    return this.httpClient.post(this.backendUrl + 'publisher/email-availability/', {email}, ApiService.getHeader());
  }

  public putPublisher(publisherModel: PublisherModel) {
    return this.httpClient.put(this.backendUrl + 'publisher/' + publisherModel.id + '/', publisherModel, ApiService.getHeader());
  }

  // Author Publication
  public postAuthorPublication(publicationModel: PublicationModel) {
    return this.httpClient.post(this.backendUrl + 'previous-author-publication/', publicationModel, ApiService.getHeader());
  }

  public putAuthorPublication(publicationModel: PublicationModel) {
    return this.httpClient.put(
      this.backendUrl + 'previous-author-publication/' + publicationModel.id + '/',
      publicationModel,
      ApiService.getHeader()
    );
  }

  // Publisher Publication
  public postPublisherPublication(publicationModel: PublicationModel) {
    return this.httpClient.post(this.backendUrl + 'previous-publisher-publication/', publicationModel, ApiService.getHeader());
  }

  public putPublisherPublication(publicationModel: PublicationModel) {
    return this.httpClient.put(
      this.backendUrl + 'previous-publisher-publication/' + publicationModel.id + '/',
      publicationModel,
      ApiService.getHeader()
    );
  }

  // Publication Cost
  public postPublicationCost(publicationCostModel: PublicationCostModel) {
    return this.httpClient.post(this.backendUrl + 'publication-cost/', publicationCostModel, ApiService.getHeader());
  }

  public putPublicationCost(publicationCostModel: PublicationCostModel) {
    return this.httpClient.put(
      this.backendUrl + 'publication-cost/' + publicationCostModel.id + '/',
      publicationCostModel,
      ApiService.getHeader()
    );
  }
}
